export function collapseMenu() {
  // collapse menu on click
  const navLinks = document.querySelectorAll('.nav-link');
  const menuToggle = document.getElementById('offcanvasMenu');
  const bsOffcanvas = new bootstrap.Offcanvas(menuToggle);
  navLinks.forEach((l) => {
    l.addEventListener('click', () => {
      bsOffcanvas.hide();
    });
  });
}
